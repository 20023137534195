import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRangeSlider } from 'vuetify/lib/components/VRangeSlider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"mt-2"},[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VCard,[_c('div',{staticClass:"d-flex align-center justify-space-between mb-4"},[_c('div',[_c(VCardTitle,{staticClass:"text-no-wrap"},[_vm._v(" Configuração de Range ")]),_c(VCardSubtitle,[_vm._v("Check out each Column for more details")])],1),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VAvatar,_vm._g({staticClass:"v-avatar-light-bg primary--text me-5",attrs:{"color":"primary","size":"48"}},on),[_c(VIcon,{attrs:{"size":"30","color":"primary"}},[_vm._v(" mdi-help-circle-outline ")])],1)]}}])},[_vm._v(" Sobre ")])],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Ativar range")]),_c(VSwitch,{staticClass:"mt-0",attrs:{"hide-details":"auto","input-value":true}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VRangeSlider,{attrs:{"hide-details":"","show-ticks":"always","thumb-label":"always","ticks":"always","tick-size":"4","max":3,"min":0,"step":"1"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VTextField,{staticStyle:{"width":"70px"},attrs:{"hide-details":"","outlined":"","single-line":"","type":"number","variant":"outlined","density":"compact"},model:{value:(_vm.configuracao.range[0]),callback:function ($$v) {_vm.$set(_vm.configuracao.range, 0, $$v)},expression:"configuracao.range[0]"}})]},proxy:true},{key:"append",fn:function(){return [_c(VTextField,{staticStyle:{"width":"70px"},attrs:{"hide-details":"","outlined":"","single-line":"","type":"number","variant":"outlined","density":"compact"},model:{value:(_vm.configuracao.range[1]),callback:function ($$v) {_vm.$set(_vm.configuracao.range, 1, $$v)},expression:"configuracao.range[1]"}})]},proxy:true}]),model:{value:(_vm.configuracao.range),callback:function ($$v) {_vm.$set(_vm.configuracao, "range", $$v)},expression:"configuracao.range"}})],1)],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VCard,[_c('div',{staticClass:"d-flex align-center justify-space-between mb-4"},[_c('div',[_c(VCardTitle,{staticClass:"text-no-wrap"},[_vm._v(" Configuração Opcionais ")]),_c(VCardSubtitle,[_vm._v(" Check out each Column for more details ")])],1),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VAvatar,_vm._g({staticClass:"v-avatar-light-bg primary--text me-5",attrs:{"color":"primary","size":"48"}},on),[_c(VIcon,{attrs:{"size":"30","color":"primary"}},[_vm._v(" mdi-help-circle-outline ")])],1)]}}])},[_vm._v(" Sobre ")])],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"outlined":"","clearable":"","clear-icon":'mdi-close-circle-outline',"label":"Detalhamento da meta","hide-details":""},model:{value:(_vm.configuracao.detalhamento),callback:function ($$v) {_vm.$set(_vm.configuracao, "detalhamento", $$v)},expression:"configuracao.detalhamento"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","label":"Fonte de apuração","placeholder":"Descreva a fonte na qual essa meta pode ser apurada","clearable":"","hide-details":""},model:{value:(_vm.configuracao.fonte_apuracao),callback:function ($$v) {_vm.$set(_vm.configuracao, "fonte_apuracao", $$v)},expression:"configuracao.fonte_apuracao"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{staticClass:"select-router-transition",attrs:{"items":_vm.perspectivasBSC,"item-text":"text","item-value":"value","menu-props":{
                  offsetY: true,
                  contentClass: 'list-style',
                },"label":"Pespectiva BSC","outlined":"","hide-details":"","dense":"","clearable":""},model:{value:(_vm.configuracao.perspectiva_bsc),callback:function ($$v) {_vm.$set(_vm.configuracao, "perspectiva_bsc", $$v)},expression:"configuracao.perspectiva_bsc"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }