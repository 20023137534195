<template>
  <div>
    <v-row class="mt-2">
      <v-col cols="12" md="12">
        <v-card>
          <div class="d-flex align-center justify-space-between mb-4">
            <div>
              <v-card-title class="text-no-wrap">
                Configuração de Range
              </v-card-title>
              <v-card-subtitle>Check out each Column for more details</v-card-subtitle>
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-avatar color="primary" size="48" class="v-avatar-light-bg primary--text me-5" v-on="on">
                  <v-icon size="30" color="primary">
                    mdi-help-circle-outline
                  </v-icon>
                </v-avatar>
              </template>
              Sobre
            </v-tooltip>
          </div>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h4>Ativar range</h4>
                <v-switch hide-details="auto" :input-value="true" class="mt-0"></v-switch>
              </v-col>
              <v-col cols="12">
                <v-range-slider hide-details show-ticks="always" thumb-label="always" ticks="always" tick-size="4"
                  :max="3" :min="0" step="1" v-model="configuracao.range">
                  <template v-slot:prepend>
                    <v-text-field v-model="configuracao.range[0]" hide-details outlined single-line type="number"
                      variant="outlined" density="compact" style="width: 70px"></v-text-field>
                  </template>
                  <template v-slot:append>
                    <v-text-field v-model="configuracao.range[1]" hide-details outlined single-line type="number"
                      variant="outlined" style="width: 70px" density="compact"></v-text-field>
                  </template>
                </v-range-slider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-card>
          <div class="d-flex align-center justify-space-between mb-4">
            <div>
              <v-card-title class="text-no-wrap">
                Configuração Opcionais
              </v-card-title>
              <v-card-subtitle>
                Check out each Column for more details
              </v-card-subtitle>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-avatar color="primary" size="48" class="v-avatar-light-bg primary--text me-5" v-on="on">
                  <v-icon size="30" color="primary">
                    mdi-help-circle-outline
                  </v-icon>
                </v-avatar>
              </template>
              Sobre
            </v-tooltip>
          </div>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="configuracao.detalhamento" outlined clearable
                  :clear-icon="'mdi-close-circle-outline'" label="Detalhamento da meta" hide-details></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="configuracao.fonte_apuracao" outlined label="Fonte de apuração"
                  placeholder="Descreva a fonte na qual essa meta pode ser apurada" clearable hide-details></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select :items="perspectivasBSC" item-text="text" item-value="value"
                  v-model="configuracao.perspectiva_bsc" :menu-props="{
                    offsetY: true,
                    contentClass: 'list-style',
                  }" label="Pespectiva BSC" outlined hide-details dense clearable
                  class="select-router-transition"></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "@/store";
import { ref, computed } from "@vue/composition-api";

export default {
  name: "configuracao-extra-Component",
  components: {},
  setup(_, { emit }) {
    let configuracao = computed({
      get() {
        return store.getters["avdTecnicas/getConfiguracao"];
      },
      set(value) {
        store.dispatch("avdTecnicas/setConfiguracao", value);
      },
    });
    const perspectivasBSC = ref([
      {
        text: "Não utiliza",
        value: "N",
      },
      {
        text: "Perspectiva Financeira",
        value: "F",
      },
      {
        text: "Perspectiva dos Clientes",
        value: "C",
      },
      {
        text: "Perspectiva dos Processos Internos",
        value: "P",
      },
      {
        text: "Perspectiva de Aprendizado e Crescimento",
        value: "A",
      },
    ]);

    return {
      // Variables
      configuracao,
      perspectivasBSC,
    };
  },
};
</script>

<style></style>
